import { Alert, Layout, Row, Space, Typography } from "antd";
import { Key, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleGrey from "../../../assets/icons/add-circle-grey.svg";
import ArrowRightBackBlackIcon from "../../../assets/icons/arrow-right-white-back-black.svg";
import DoubleCard from "../../../assets/icons/double-card.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import CreatorDeleteAlbumModal from "../../../components/CreatorDeleteAlbumModal";
import { usePreviousLocationContext } from "../../../helpers/PreviousLocationContext";
import { IAlbumState } from "../../../helpers/types";
import { getQuickFileAlbum, getQuickFilesSize } from "../../../redux/quickFile/quickFileSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import "./index.less";

const CreatorQuickFileComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = usePreviousLocationContext();
  const shouldFetch = useRef(true);
  const [queryParams, setQueryParams] = useState({ id: "", conversationId: "", messageId: "", receiverId: "" });
  const dispatch = useDispatch<AppDispatch>();
  const { list: quickFileAlbumList, folderSize } = useSelector((state: StoreState) => state.quickFile);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [albumIdToDelete, setAlbumIdToDelete] = useState<string>("");

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getQuickFileAlbum());
      dispatch(getQuickFilesSize());
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id") || "";
    const conversationId = params.get("conversationId") || "";
    const receiverId = params.get("receiverId") || "";
    const messageId = params.get("messageId") || "";
    setQueryParams({ id, conversationId, receiverId, messageId });
  }, [location.search]);

  const handleNavigation = (): string => {
    if (queryParams.id === "collection") {
      return "/creator/create/collection";
    }
    if (queryParams?.conversationId) {
      return `/creator/mediaPush?conversation=${true}&conversationId=${queryParams?.conversationId}&receiverId=${queryParams?.receiverId}&messageId=${queryParams?.messageId}`;
    }
    if (["/creator/createNewAlbum"].includes(previousLocation?.pathname!) || previousLocation?.pathname.includes("/creator/AddMedia")) {
      return "/common/setting";
    }
    return "";
  };

  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Content className="content CreatorQuickLinkContentWrapper background-transparent">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent left={-2} top={10} navigateTo={handleNavigation()} />
          <Typography.Text className="ml-15 header-title-black-35">{t("Quick-Files")}</Typography.Text>
        </Row>
        {!queryParams?.id && (
          <Space className="w-full items-center mt-16" direction="vertical">
            <div>
              <img onClick={() => navigate("/creator/createNewAlbum")} src={AddCircleGrey} width={170} alt="plus" />
            </div>
            <Typography.Text className="CreateNewAlbum">{t("click-to-create-a-new-album")}</Typography.Text>
          </Space>
        )}

        {folderSize && (
          <Space direction="vertical" className="w-full mt-25">
            <Alert message={t("storage-max-size-warning-message")} type="warning" showIcon closable />
          </Space>
        )}

        <Space direction="vertical" className="w-full mt-25">
          <Typography.Text className="MyAlbums">{t(`My-albums`)}</Typography.Text>
        </Space>
        {quickFileAlbumList?.map((item: IAlbumState, index: Key) => {
          return (
            <Space key={index} direction="vertical" className="w-full mt-3">
              <div className="albumRowContainer">
                <Row
                  className="albumRow"
                  onClick={() => {
                    queryParams?.id
                      ? queryParams?.id === "mediaPush"
                        ? navigate(
                            `/creator/AlbumFeeds/${item.id}?id=${queryParams?.id}&conversationId=${queryParams.conversationId}&messageId=${queryParams.messageId}&receiverId=${queryParams.receiverId}`
                          )
                        : navigate(`/creator/AlbumFeeds/${item.id}?id=${queryParams?.id}&conversationId=${queryParams.conversationId}`)
                      : queryParams?.id === "collection"
                        ? navigate(`/creator/AlbumFeeds/${item.id}?id=${queryParams?.id}`)
                        : navigate("/creator/AlbumFeeds/" + item.id);
                  }}
                >
                  <Row className="elementsRow" align="middle">
                    <img className="doubleCard" src={DoubleCard} width={20} alt="" />
                    <Typography.Text className="QuickLinkAlbumName">{t(`${item.name}`)}</Typography.Text>
                    <div className="rightElements">
                      <Typography.Text className="QuickLinkNumberOfFeeds">{t(`(${item.filesNumber})`)}</Typography.Text>
                      <div className="chevron-right">
                        <img width={40} src={ArrowRightBackBlackIcon} alt="arrow-back" />
                      </div>
                    </div>
                  </Row>
                </Row>
                <img
                  src={TrashIcon}
                  width={36}
                  className="trashIcon"
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setAlbumIdToDelete(item.id);
                  }}
                  alt="trash"
                />
              </div>
            </Space>
          );
        })}
        <CreatorDeleteAlbumModal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} albumId={albumIdToDelete} />
      </Layout.Content>
    </Layout>
  );
};

export default CreatorQuickFileComponent;
