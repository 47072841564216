import LiveGiftVideo from "../../assets/videos/live-gift.mp4";
import LiveGiftImg from "../../assets/images/live-gift-img.webp";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./index.less";

const FanDiscoverLiveGiftComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [hasVideoPlayed, setHasVideoPlayed] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    const videoPlayed = localStorage.getItem("hasVideoPlayed");

    if (video) {
      video.play();
    }

    if (videoPlayed) {
      setHasVideoPlayed(true);
    }
  }, []);

  const endVideo = () => {
    setIsVideoEnded(true);
    localStorage.setItem("hasVideoPlayed", "true");
  };

  return (
    <div className="live-gift-container">
      {!hasVideoPlayed ? (
        <>
          <video
            ref={videoRef}
            src={LiveGiftVideo}
            width="100%"
            autoPlay
            muted
            playsInline
            loop={false}
            onEnded={endVideo}
            style={{ display: "block", objectFit: "cover", backgroundColor: "#111111" }}
          />
          {isVideoEnded && (
            <button
              className="video-overlay-button"
              onClick={() => {
                navigate("/p/fan/lives?tab=schedule");
              }}
            >
              {t("discover-lives")}
            </button>
          )}
        </>
      ) : (
        <div>
          <img className="live-img" src={LiveGiftImg} alt="Live" />
          <button
            className="video-overlay-button"
            onClick={() => {
              navigate("/p/fan/lives?tab=schedule");
            }}
          >
            {t("discover-lives")}
          </button>
        </div>
      )}
    </div>
  );
};

export default FanDiscoverLiveGiftComponent;
