import { Layout, Row, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LiveIcon from "../../../assets/icons/home-live-button.svg";
import Popcorn from "../../../assets/images/popcorn.webp";
import FanHomeSuggestionComponent from "../../../components/FanHomeSuggestionComponent";
import FanPhoneModalComponent from "../../../components/FanPhoneModalComponent";
import FooterComponent from "../../../components/FooterComponent";
import MainSwiper from "../../../components/MainSwiper";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IUser } from "../../../helpers/types";
import { StoreState } from "../../../redux/store";
import { authService, userService } from "../../../services";
import FanDiscoverLiveGiftComponent from "../../../components/FanDiscoverLiveGiftComponent";
import "./index.less";

const FanHomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user: IUser = authService.getUser();
  const [phoneModal, setPhoneModal] = useState<boolean>(false);
  const { homeFeeds } = useSelector((state: StoreState) => state.feeds);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (localStorage.getItem("phoneModalShown") === null || !localStorage.getItem("phoneModalShown")) {
      userService.getPhoneModalShown().then((data) => {
        if (!data.phoneModalShown) {
          setPhoneModal(true);
        }
      });
    }
  }, [user]);

  const closePhoneModal = () => {
    setPhoneModal(false);
    userService.updatePhoneModalShown(true);
    localStorage.setItem("phoneModalShown", JSON.stringify(true));
  };

  useEffect(() => {
    if (localStorage.getItem("phoneModalShown") === null || !localStorage.getItem("phoneModalShown")) {
      userService.getPhoneModalShown().then((data) => {
        if (!data.phoneModalShown) {
          setPhoneModal(true);
        }
      });
    }
  }, [user]);

  const handleScroll = () => {
    if (scrollRef.current) {
      localStorage.removeItem("isHome");
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "start",
      });
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      const isHome = localStorage.getItem("isHome");

      if (isHome === "true") {
        handleScroll();
      }
    };

    window.addEventListener("localStorageChanged", handleStorageChange);

    return () => {
      window.removeEventListener("localStorageChanged", handleStorageChange);
    };
  }, []);

  return (
    <Layout>
      <Layout.Content className="header-background-fan fanHomeContentFeedWrapper no-scroll-horizontal">
        <div ref={scrollRef}>
          <Row className="header-row" align="middle">
            <Typography.Text className="title-home-fan">
              {t("for-1")} {user.firstName}
            </Typography.Text>
            <img src={Popcorn} width={24} className="header-icon" alt="Loly-popcorn" />
            <div
              className="liveButton"
              onClick={() => {
                navigate("/p/fan/lives?tab=schedule");
              }}
            >
              <img src={LiveIcon} className="mr-7" width={14} alt="Loly-popcorn" />
              <Typography.Text className="text-white-color font-14-bold">LIVE</Typography.Text>
            </div>
          </Row>
          <FanDiscoverLiveGiftComponent />
          <MainSwiper />
          {homeFeeds.length === 0 && <FanHomeSuggestionComponent showSection4={true} />}
        </div>
        <FanPhoneModalComponent
          visible={phoneModal}
          onClose={() => {
            closePhoneModal();
          }}
        />
      </Layout.Content>
      <FooterComponent type="fan" page={FOOTER_IDS.HOME} />
    </Layout>
  );
};

export default FanHomePage;
